import React from "react"
import PropTypes from "prop-types"

import SelectLanguage from '../components/select-language'
import RoundLogo from "../components/roundlogo"
import StoryImage from "./story-image"
import { FormattedMessage } from 'react-intl'


const About = (props) => {
    console.log('props', props)
    return (
        <div
            className="bg-gray-750 shadow-inner grid grid-cols-2 lg:grid-cols-1 sm:grid-cols-2 lg:h-screen">
            <div className="flex lg:items-stretch">
                {/* <div className="mx-auto flex justify-center 2xl:mt-32 lg:mt-20 mt-12" style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}> */}
                <div className="mx-auto self-center lg:self-end p-6 lg:p-0" >
                    <RoundLogo />
                </div>
            </div>
            <div className="flex flex-col">
                
                <div className="lg:mx-auto pt-6 lg:pt-6 ml-0 pr-6 lg:pr-0">
                    <p className="antialiased tracking-normal font-light lg:text-4xl sm:text-2xl text-xl text-white leading-6 pb-1 sm:pb-0">{props.name}</p>

                </div>

                <div className="lg:pt-2 pt-1 lg:mx-auto ml-0">
                    <p className="antialiased tracking-wider font-semibold lg:text-base text-xs text-white uppercase w-10/12 sm:w-full"><FormattedMessage id="LABEL_DIGITAL_PRODUCTS_DESIGNER" /></p>
                </div>
            <div className="hidden sm:inline lg:mx-auto justify-center">                
                <div className="lg:pt-3 pt-7 lg:mx-auto ml-0 lg:text-center">
                    <p className="antialiased tracking-normal font-light lg:text-xl sm:text-lg text-base text-white"><FormattedMessage id="LABEL_UX_AND_DESIGN" /></p>
                </div>
                <div className="lg:mx-auto ml-0 lg:text-center">
                    <p className="antialiased tracking-normal font-light lg:text-xl sm:text-lg text-base text-white"><FormattedMessage id="LABEL_HCI" /></p>
                </div>
            </div>        
                <div className="flex flex-row pt-3 lg:pb-20 pb-6 lg:justify-center items-stretch font-bold text-xs sm:text-base lg:mx-auto -ml-1">
                    <div>
                        <a href="https://budarevskaya.pro/contact">

                            <div className="self-center mr-2 opacity-70 hover:opacity-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"> 
                            <svg className="w-8 text-gray-200 hover:text-blue-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="currentColor">
                            <path d="M44.8,15.8H17.5c-2,0-3.6,1.6-3.6,3.6v18.4c0,2,1.6,3.6,3.6,3.6h27.3c2,0,3.6-1.6,3.6-3.6V19.4
                            C48.3,17.4,46.7,15.8,44.8,15.8z M16.3,23l12,12.1c0.7,0.7,1.7,1.1,2.7,1.1c1,0,2-0.4,2.7-1.1l12-12v14.8c0,0.5-0.5,1.1-1.1,1.1
                            H17.5c-0.6,0-1.1-0.5-1.1-1.1V23z M30.2,33.4L16.3,19.6v-0.2c0-0.6,0.5-1.1,1.1-1.1h27.3c0.6,0,1.1,0.5,1.1,1.1v0.1L32.1,33.4
                            C31.6,33.9,30.6,33.9,30.2,33.4z"/>
                            </svg>                               
                            </div>
                        </a>
                    </div>
                    <div>
                        <a target="_blank" href="https://www.linkedin.com/in/maria-budarevskaya-30507438/">

                        <div className="self-center mr-2 opacity-70 hover:opacity-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                        <svg className="w-8 text-gray-200 hover:text-blue-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="currentColor">
                        <g>
                            <rect x="19.2" y="24.5" width="5.1" height="16.5"/>
                            <path d="M21.7,16.3c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9C24.7,17.7,23.4,16.3,21.7,16.3z"/>
                            <path d="M37.3,24.1c-2.5,0-4.2,1.3-4.8,2.7h-0.1v-2.3h-4.9v16.5h5.1v-8.1c0-2.2,0.4-4.3,3-4.3s2.7,2.5,2.7,4.4v8h5.2v-9
                                C43.4,27.5,42.5,24.1,37.3,24.1z"/>
                        </g>
                        </svg>
                            {/* <svg xmlns="http://www.w3.org/2000/svg"  className="bg-gray-200 hover:bg-blue-150 inline w-10" viewBox="0 0 64 64"  >
                                                        <path  d="M30.7,8.8C15.9,8.8,4,20.8,4,35.5c0,14.8,12,26.7,26.7,26.7c14.8,0,26.7-12,26.7-26.7C57.4,20.8,45.5,8.8,30.7,8.8z
                                M24.8,46.9h-5.4V29.5h5.4V46.9z M22.1,27.1c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1S23.8,27.1,22.1,27.1z
                                M45,46.9h-5.5v-8.5c0-2,0-4.6-2.8-4.6s-3.2,2.2-3.2,4.5v8.6h-5.4V29.5h5.2v2.4h0.1c0.7-1.4,2.5-2.8,5.1-2.8c5.5,0,6.5,3.6,6.5,8.3
                                V46.9z"/>
                            </svg>   */}
                                
                            </div>
                        </a>
                    </div>

                    {/* <span className="self-center antialiased tracking-wide lg:mt-1 mt-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:underline"><SelectLanguage {...props} /></span> */}


                </div>
            </div>
        </div>
    )
}

export default About