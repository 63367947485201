import React from "react"
import StoryImage from "./story-image"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FormattedMessage } from 'react-intl';



const LandingBlock= ({data}) => {
    // console.log(`story data ${data.title}`, data)
    return (
        <>
 
   <div id={data.name} className="z-10 hover:z-50"> <section className="text-gray-700 body-font flex md:flex-row flex-col items-center hover:bg-white
    transition duration-500 ease-in-out transform hover:shadow-inner hover:-translate-y-3 hover:scale-105">
        <div
            className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 lg:p-0 pl-10">
                 
            <h1 className="title-font lg:text-4xl sm:text-2xl text-xl text-gray-900 font-light w-11/12 pt-20 md:p-0" dangerouslySetInnerHTML={{__html:data.title}}></h1>
            <p className="leading-relaxed uppercase font-semibold lg:text-base text-xs lg:pt-2 pt-1" dangerouslySetInnerHTML={{__html:data.subtitle}}></p>
            <p className="antialiased pt-1 w-10/12 lg:text-base text-sm" dangerouslySetInnerHTML={{__html:data.teaser}}>
            
            </p>
            
            {data.teaser_links && <span className="antialiased pt-1 w-10/12 lg:text-base sm:text-sm text-xs">
                {data.teaser_links.map((ref)=>
                    <span key={ref.href}>
                    <span dangerouslySetInnerHTML={{__html:ref.pre_text}}></span>    
                    <a className="font-bold text-blue-500 hover:underline" href={ref.href} dangerouslySetInnerHTML={{__html:ref.link_text}}></a>
                    <span dangerouslySetInnerHTML={{__html:ref.post_text}}></span>
                    </span>
                    )}
            </span>}

            
            {data.has_details && (
                <AniLink paintDrip color="gainsboro" to={data.slug}>
                    <button className="lg:text-base sm:text-sm text-xs mb-2 lg:mt-8 mt-3 pl-4 pr-4 focus:outline-none border-2 border-blue-50 rounded-full font-bold text-blue-150 px-2 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                        {/* <FormattedMessage id="see_the_story"/> */}
                    <FormattedMessage id="see_the_story"/>
                    </button>
                </AniLink>
            )}

            {!data.has_details && (
                <div>
                <p className="antialiased pt-4 w-10/12 lg:text-base sm:text-sm text-xs opacity-30">
                    <FormattedMessage id="coming_soon"/>
                </p>
                </div>
            )}
            
        </div>
        <div className="md:w-1/2 w-full z-10 hover:z-50">
            <div className="flex-grow h-auto">
                <div className="object-cover object-center">
                
                {data.has_details && (
                    <AniLink paintDrip color="gainsboro" to={data.slug}>
                            <StoryImage alt="" filename={`${data.landing_image}`} /> 
                    </AniLink>
                )}

                {!data.has_details && (                
                    <StoryImage alt="" filename={`${data.landing_image}`} /> 
                )}
                </div>
             
             </div>
        </div>
    </section></div>

</>
    )
}

export default LandingBlock


