import React from "react"
import PropTypes from "prop-types"
// import scrollTo from 'gatsby-plugin-smoothscroll'
import { FormattedMessage } from 'react-intl'
import SmoothScrolling from "./smoothscrolling";


const SummaryBlock = () => {
    const goToNext = () => SmoothScrolling.scrollTo('nextpage');
    return (
        <>
            <div className="bg-white top-10 p-10">
                <div className="flex flex-row justify-between items-stretch pb-3">
                    <h2 className="w-3/5 antialiased tracking-wider leading-relaxed uppercase font-semibold text-xs lg:text-base">

                        <FormattedMessage id="LABEL_SKILLS" />
                        <br /><span className="opacity-30 font-normal text-xs lg:text-base">

                            <FormattedMessage id="LABEL_JOKE" />
                        </span></h2>
                        {/* scrollTo('#nextpage') */}
                    <div className="w-2/5 flex justify-end items-center">
                        <button className="-mt-1 self-start focus:outline-none border-2 border-blue-50 rounded-full font-bold text-blue-150 px-2 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105" 
                             tabIndex="1" onClick={() => goToNext() }>
                            <span className="hidden sm:inline sm:pl-2">

                                <FormattedMessage id="LABEL_PORTFOLIO" />
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="inline sm:ml-1 w-6 stroke-current stroke-2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" 
                                viewBox="0 0 24 24" fill="none" 
                                strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19" />
                                    <polyline points="19,12 12,19 5,12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="lg:text-xl sm:text-lg text-base pb-8">
                    <div className="flex flex-row items-stretch">
                   
                        <p className="antialiased pt-6 self-center justify-self-start">
                        
                        <span className="relative">
                            <span style={{height:'6px'}} className="absolute top-5 w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                            <FormattedMessage id="LABEL_SKILL1_TITLE" />
                        </span>
                        <FormattedMessage id="LABEL_SKILL1" />
                        </p>
                       
                    </div>
                    <div className="flex flex-row items-stretch">
                   
                        <p className="antialiased pt-6 self-center justify-self-start">
                        <span className="relative">
                        <span style={{height:'6px'}} className="absolute top-5  w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                            <FormattedMessage id="LABEL_SKILL2_TITLE" />
                        </span>    
                            <FormattedMessage id="LABEL_SKILL2" />
                        </p>
                        
                    </div>
                    <div className="flex flex-row items-stretch">
                      
                        <p className="antialiased pt-6 self-center justify-self-start">
                        <span className="relative">
                        <span style={{height:'6px'}} className="absolute top-5  w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                            <FormattedMessage id="LABEL_SKILL3_TITLE" />
                        </span>  
                            <FormattedMessage id="LABEL_SKILL3" />
                        </p>
                       
                    </div>
                    <div className="flex flex-row items-stretch">
                      
                        <p className="antialiased pt-6 self-center justify-self-start">
                        <span className="relative">
                            <span style={{height:'6px'}} className="absolute top-5  w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                            <FormattedMessage id="LABEL_SKILL4_TITLE" />
                        </span> 
                            <FormattedMessage id="LABEL_SKILL4" />
                        </p>
                        
                    </div>
                    <div className="flex flex-row items-stretch">
                     
                        <p className="antialiased pt-6  self-center justify-self-start">
                        <span className="relative">
                            <span style={{height:'6px'}} className="absolute top-5  w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                            <FormattedMessage id="LABEL_SKILL5_TITLE" />
                        </span> 
                            <FormattedMessage id="LABEL_SKILL5" />
                        </p>
                        
                    </div>
                    <div className="flex flex-row items-stretch">
                     
                        <p className="antialiased pt-6 self-center justify-self-start">
                        <span className="relative">
                            <span style={{height:'6px'}} className="absolute top-5  w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                            <FormattedMessage id="LABEL_SKILL6_TITLE" />
                        </span> 
                            <FormattedMessage id="LABEL_SKILL6" />
                        </p>
                        
                    </div>
                    <div className="flex flex-row items-stretch">
                        
                        <p className="antialiased pt-6 self-center justify-self-start">
                        <span className="relative">
                            <span style={{height:'6px'}} className="absolute top-5  w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                            <FormattedMessage id="LABEL_SKILL7_TITLE" />
                        </span> 
                            <FormattedMessage id="LABEL_SKILL7" />
                        </p>
                       
                    </div>
                    <div className="flex flex-row items-stretch">
                       
                        <p className="antialiased pt-6 self-center justify-self-start">
                        <span className="relative">
                            <span style={{height:'6px'}} className="absolute top-5  w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                            <FormattedMessage id="LABEL_SKILL8_TITLE" />
                        </span> 
                            <FormattedMessage id="LABEL_SKILL8" />
                        </p>
                        
                    </div>
                    <div className="flex flex-row items-stretch">
                       
                       <p className="antialiased pt-6 self-center justify-self-start">
                       <span className="relative">
                           <span style={{height:'6px'}} className="absolute top-5  w-full bg-opacity-30 bg-orange-500" aria-hidden="true"></span>
                           <FormattedMessage id="LABEL_SKILL9_TITLE" />
                       </span> 
                           <FormattedMessage id="LABEL_SKILL9" />
                       </p>
                       
                   </div>                   
                    {/* <span className="opacity-20 transform rotate-3 text-xl left-2 self-center w-6">&#128062;</span>
                    <span className="opacity-20 transform -rotate-12 text-xl left-4 self-center w-6">&#128062;</span>
                    <span className="opacity-20 text-xl left-2 self-center w-6">&#128062;</span>
                    <span className="opacity-20 text-xl transform -rotate-45 left-4 self-center w-6">&#128062;</span>
                    <span className="opacity-20 text-xl transform rotate-12 left-2 self-center w-6">&#128062;</span>
                    <span className="opacity-20 text-xl transform -rotate-12 left-4 self-center w-6">&#128062;</span>
                    <span className="opacity-20 text-xl transform -rotate-45 left-2 self-center w-6">&#128062;</span>
                    <span className="opacity-20 text-xl transform rotate-3 left-4 self-center w-6">&#128062;</span> */}

                    {/* <p className="antialiased pt-8 pl-14 relative"><span className="transform rotate-3 opacity-25 text-xl absolute left-4">&#128062;</span> Анализ аналитики и&nbsp;тестирование вариантов по&nbsp;улучшению показателей продукта</p> */}

                </div>
                
            </div>
            
            
        </>
    )
}

export default SummaryBlock


