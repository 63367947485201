import React from "react"
import PropTypes from "prop-types"
// import scrollTo from 'gatsby-plugin-smoothscroll'
import SmoothScrolling from "./smoothscrolling";
import { FormattedMessage } from 'react-intl'


const Footer = () => {
    const goToAbout = () => SmoothScrolling.scrollTo('about');
    return (
        

        <footer
        // style={{
        //   marginTop: `2rem`,
        // }}
        >
            <div className="bg-white top-10 p-10 relative overflow-clip overflow-hidden">
                <div className="items-stretch">
                    <div className="mx-auto flex justify-end absolute right-10 self-center">
                        <button className="self-start focus:outline-none border-2 border-blue-50 rounded-full font-bold text-blue-150 px-2 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105" 
                                onClick={() => goToAbout()}>
                            <span className="hidden sm:inline sm:pl-2"><FormattedMessage id="LABEL_GO_UP" /></span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="inline sm:ml-1 w-6 stroke-current stroke-2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" viewBox="0 0 24 24" fill="none" 
                                strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="19" x2="12" y2="5" /><polyline points="5,12 12,5 19,12" /></svg>
                        </button>
                    </div>
                    <p className="text-left text-xs flex self-center" >
                        © 2005–{new Date().getFullYear()} <FormattedMessage id="LABEL_COPYRIGHT" /><br />
                        {/* {` `} */}

                    </p>
                    <div className="text-left text-xs flex self-center">
                        <div className="flex flex-row pt-1 lg:-mt-8 lg:pb-20 pb-6 lg:justify-center items-stretch font-bold text-xs sm:text-base lg:mx-auto -ml-1">
                            <div>
                                <a href="https://budarevskaya.pro/contact">

                                    <div className="self-center mr-2 opacity-70 hover:opacity-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                        <svg className="w-8 text-gray-900 hover:text-blue-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="currentColor">
                                            <path d="M44.8,15.8H17.5c-2,0-3.6,1.6-3.6,3.6v18.4c0,2,1.6,3.6,3.6,3.6h27.3c2,0,3.6-1.6,3.6-3.6V19.4
                            C48.3,17.4,46.7,15.8,44.8,15.8z M16.3,23l12,12.1c0.7,0.7,1.7,1.1,2.7,1.1c1,0,2-0.4,2.7-1.1l12-12v14.8c0,0.5-0.5,1.1-1.1,1.1
                            H17.5c-0.6,0-1.1-0.5-1.1-1.1V23z M30.2,33.4L16.3,19.6v-0.2c0-0.6,0.5-1.1,1.1-1.1h27.3c0.6,0,1.1,0.5,1.1,1.1v0.1L32.1,33.4
                            C31.6,33.9,30.6,33.9,30.2,33.4z"/>
                                        </svg>
                                    </div>
                                </a>
                            </div>
                            <div>
                                <a target="_blank" href="https://www.linkedin.com/in/maria-budarevskaya-30507438/">

                                    <div className="self-center mr-2 opacity-70 hover:opacity-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                        <svg className="w-8 text-gray-900 hover:text-blue-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="currentColor">
                                            <g>
                                                <rect x="19.2" y="24.5" width="5.1" height="16.5" />
                                                <path d="M21.7,16.3c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9C24.7,17.7,23.4,16.3,21.7,16.3z" />
                                                <path d="M37.3,24.1c-2.5,0-4.2,1.3-4.8,2.7h-0.1v-2.3h-4.9v16.5h5.1v-8.1c0-2.2,0.4-4.3,3-4.3s2.7,2.5,2.7,4.4v8h5.2v-9
                                C43.4,27.5,42.5,24.1,37.3,24.1z"/>
                                            </g>
                                        </svg>


                                    </div>
                                </a>
                            </div>




                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer


